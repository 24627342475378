import React, { createContext, useState } from "react";

export const ChatRoomDetailContext = createContext();

const ChatRoomDetailProvider = ({ children }) => {
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);

  const updateSelectedChatRoom = (newChatRoom) => {
    setSelectedChatRoom(newChatRoom);
  };

  return (
    <ChatRoomDetailContext.Provider
      value={{ selectedChatRoom, updateSelectedChatRoom }}
    >
      {children}
    </ChatRoomDetailContext.Provider>
  );
};

export { ChatRoomDetailProvider };

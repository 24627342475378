import { Controller } from 'stimulus';
import Pikaday from 'pikaday';

export default class extends Controller {
  static targets = [];

  static classes = [];

  static values = {};

  connect() {
    this.picker = new Pikaday({
      field: this.element,
    });
  }
}

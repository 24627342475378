import React, { useContext } from 'react';
import { ChatRoomDetailContext } from '../../context/chatRoomDetailsContext';
import { useMessages } from '../../hooks/useMessages';
import { sendMessage } from '../../services/firebase';
import './style.scss';
import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  MessageSeparator,
} from '@chatscope/chat-ui-kit-react';
import { formateTime, fullDay } from '../../utils/date_utils.js';

const ChatRoomDetailView = ({ chatContainerStyle, handleBackClick }) => {
  const { selectedChatRoom } = useContext(ChatRoomDetailContext);

  if (!selectedChatRoom) {
    return (
      <ChatContainer style={chatContainerStyle}>
        <MessageList>
          <MessageList.Content
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
              textAlign: 'center',
              fontSize: '1.2em',
            }}
          >
            Select a conversation to continue
          </MessageList.Content>
        </MessageList>
      </ChatContainer>
    );
  }

  const { isLoading, messages } = useMessages(selectedChatRoom);

  const handleSend = (message) => {
    sendMessage(selectedChatRoom.id, selectedChatRoom.current_user.user_id, message);
  };

  return (
    <ChatContainer style={chatContainerStyle}>
      <ConversationHeader>
        <ConversationHeader.Back onClick={handleBackClick} />
        <ConversationHeader.Content userName={selectedChatRoom.opp_user.display_name} />
      </ConversationHeader>
      {isLoading ? (
        <MessageList loading />
      ) : (
        <MessageList>
          {messages.map((message) => {
            if (typeof message === 'string') {
              return <MessageSeparator key={message} content={message} />;
            }

            return (
              <Message
                key={message.id}
                model={{
                  message: message.message_body,
                  sentTime: message.sent_at && message.sent_at.toString(),
                  type: 'html',
                  direction: message.sent_by === selectedChatRoom.current_user.user_id ? 'outgoing' : 'incoming',
                }}
              >
                <Message.Footer sentTime={message.sent_at && formateTime(message.sent_at.toDate())} />
              </Message>
            );
          })}
        </MessageList>
      )}
      <MessageInput placeholder="Type message here" attachButton={false} onSend={handleSend} />
    </ChatContainer>
  );
};

export { ChatRoomDetailView };

import React from 'react';
import PropTypes from 'prop-types';
import { MainContainer } from '@chatscope/chat-ui-kit-react';
import { Landing } from '../Landing';
import './styles.scss';

class App extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <Landing userId={this.props.user.id} />
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  user: {
    id: PropTypes.string,
  },
};
export default App;

import { Controller } from 'stimulus';
import UploadedFile from '../utils/uploadedFile';

const templateContainer = 'div';

export default class extends Controller {
  static targets = ['input', 'imageTemplate', 'uploadedImage', 'placeholder'];

  static classes = ['hidden', 'error'];

  static values = { multiple: Boolean };

  connect() {
    this.directUploadUrl = this.inputTarget.dataset.directUploadUrl;
  }

  fileAdded() {
    const file = this.inputTarget.files[0];
    if (!this._validate(file)) return;

    const uploadedFile = new UploadedFile(file, this.directUploadUrl);

    const preview = this._showPreview(uploadedFile);
    uploadedFile.initializeDirectUpload((error /* , blob */) => {
      if (error) {
        this._handleError(uploadedFile, preview, error);
      }
    });
  }

  removeImage(ev) {
    ev.preventDefault();
    this.uploadedImageTargets.forEach((imageTarget) => {
      this.element.removeChild(imageTarget.parentNode);
    });
    this.inputTarget.value = '';
    this.placeholderTarget.classList.remove(this.hiddenClass);
  }

  async _showPreview(file) {
    const imagePreview = await file.readFile();
    this.placeholderTarget.classList.add(this.hiddenClass);
    const template = this.imageTemplateTarget;
    const previewTile = template.content.cloneNode(true).querySelector(templateContainer);
    previewTile.style.backgroundImage = `url('${imagePreview}')`;
    this.element.appendChild(previewTile);

    return previewTile;
  }

  _handleError(file, preview) {
    preview.classList.add(this.errorClass);
  }

  _validate(file) {
    const regex = new RegExp(/^image\//);

    return regex.test(file.type);
  }
}

import React from 'react';
import { useMessages } from '../../hooks/useMessages';
import './styles.scss';

function MessageList({ chatRoom }) {
    const containerRef = React.useRef(null);

    const messages = useMessages(chatRoom);

    React.useLayoutEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    });

    return (
        <div className="message-list-container" ref={containerRef}>
            <ul className="message-list">
                {messages.map((x) => (
                    <Message
                        key={x.id}
                        message={x}
                        isOwnMessage={x.sent_by === chatRoom.current_user.user_id}
                        chatRoom={chatRoom}
                    />
                ))}
            </ul>
        </div>
    );
}

function Message({ message, isOwnMessage, chatRoom }) {
    const { sent_by, message_body } = message;


    return (
        <li className={['message', isOwnMessage && 'own-message'].join(' ')}>
            <div>{message_body}</div>
        </li>
    );
}

export { MessageList };

import { DirectUpload } from '@rails/activestorage';

export default class {
  constructor(file, directUploadUrl) {
    this.file = file;
    this.directUploadUrl = directUploadUrl;
  }

  initializeDirectUpload(callback) {
    const upload = new DirectUpload(this.file, this.directUploadUrl);

    upload.create(callback);
  }

  readFile() {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(this.file);
    });
  }
}

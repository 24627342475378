import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  close(ev) {
    ev.preventDefault();
    this._removeContainerFor(ev.target);
    this._removeSelf();
  }

  preventCache() {
    this.element.parentNode.removeChild(this.element);
  }

  _removeContainerFor(button) {
    const container = button.closest(`[data-${this.identifier}-target="container"]`);
    container.parentNode.removeChild(container);
  }

  _removeSelf() {
    if (!this.hasContainerTarget) {
      this.element.parentNode.removeChild(this.element);
    }
  }
}

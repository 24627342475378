import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { app } from './firebaseConfig';

const messaging = getMessaging(app);

export const getFirebaseToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.messageApikey,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server
        return currentToken;
      }
      console.log('No registration token available. Request permission to generate one.');
      // shows on the UI that permission is required
      throw new Error('No registration token available.');
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
      throw err;
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';

const ListTile = ({ icon, title, subtitle }) => {
  return (
    <ListItem>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={title} secondary={subtitle} />
    </ListItem>
  );
};

ListTile.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default ListTile;

import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['hover'];

  addHoverClass() {
    this.element.classList.add(...this.hoverClass.split(' '));
  }

  removeHoverClass() {
    this.element.classList.remove(...this.hoverClass.split(' '));
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['open', 'scrolling'];

  static targets = ['menu'];

  connect() {
    this._saveScrollTop();
  }

  openMenu(ev) {
    ev.preventDefault();
    document.body.classList.add(this.openClass);
  }

  closeMenu(ev) {
    ev.preventDefault();
    document.body.classList.remove(this.openClass);
  }

  toggleNav() {
    if (this._isScrollingDown()) {
      this.element.classList.add(this.scrollingClass);
    } else if (!this._isScrollingFinished()) {
      this.element.classList.remove(this.scrollingClass);
    }
  }

  _saveScrollTop() {
    this.scrollTop = this.menuTarget.scrollTop < 0 ? 0 : this.menuTarget.scrollTop;
  }

  _isScrollingDown() {
    const result = this.scrollTop < this.menuTarget.scrollTop;
    this._saveScrollTop();
    return result;
  }

  _isScrollingFinished() {
    return this.scrollTop + this.menuTarget.offsetHeight >= this.menuTarget.scrollHeight;
  }
}

export function dateDDMMMYY(dateString) {
  if (!dateString) {
    return '';
  }
  const date = dateString.toDate();
  const options = { day: '2-digit', month: 'short', year: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}

export function formateTime(date) {
  const options = { hour: '2-digit', minute: '2-digit' };
  const formattedDate = date.toLocaleTimeString('en-US', options);
  return formattedDate;
}

export function fullDay(date) {
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const formattedDate = date.toLocaleDateString('en-US', options);

  return formattedDate;
}

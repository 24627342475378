import { Controller } from 'stimulus';

export default class extends Controller {
  static classes = ['active'];

  static targets = ['input'];

  connect() {
    if (this.inputTarget.value !== '' || this._isFocused()) {
      this.addActiveClass();
    }
  }

  addActiveClass() {
    this.element.classList.add(this.activeClass);
  }

  removeActiveClass() {
    if (this.inputTarget.value === '') {
      this.element.classList.remove(this.activeClass);
    }
  }

  _isFocused() {
    return this.inputTarget === document.activeElement;
  }
}

import { Controller } from 'stimulus';
import { getFirebaseToken } from '../services/firebaseMessage';

export default class extends Controller {
  connect() {
    this.getToken();
  }

  getToken() {
    if ('Notification' in window) {
      if (Notification.permission === 'denied') {
        // user has denied permission for push notifications
      } else if (Notification.permission === 'default') {
        // user hasn't granted or denied permission yet
      } else {
        getFirebaseToken().then((token) => {
          const hiddenField = this.element.querySelector('input[type="hidden"]');
          hiddenField.value = token;
        });
      }
    } else {
      // push notifications not supported by browser/device
    }
  }
}

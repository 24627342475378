import { Controller } from 'stimulus';
import consumer from '../utils/actioncable-consumer';

const WS_CHANNEL_NAME = 'PaymentChannel';

const successNote = "<br> If you don't see the new subscription on the list below, please reload the page.";

export default class extends Controller {
  static targets = [
    'heading',
    'message',
    'messageHeading',
    'messageContent',
    'buttonsContainer',
    'errorButton',
    'successButton',
  ];

  static classes = ['flashDefault', 'flashError', 'flashSuccess', 'hidden'];

  static values = { id: Number };

  connect() {
    if (this.idValue) {
      this._createSubscription(this.idValue);
    }
  }

  _createSubscription(orderId) {
    consumer.subscriptions.create(
      {
        channel: WS_CHANNEL_NAME,
        id: orderId,
      },
      {
        received: this._wsReceiver.bind(this),
      },
    );
  }

  _wsReceiver(data) {
    this.messageTarget.classList.remove(this.flashDefaultClass);
    this.messageHeadingTarget.innerHTML = data.payload.heading;
    this.messageContentTarget.innerHTML = data.payload.message;
    if (data.type === 'success') {
      this._handleSuccess();
    } else {
      this._handleError();
    }
  }

  _handleError() {
    this.messageTarget.classList.add(this.flashErrorClass);
    this.errorButtonTarget.classList.remove(this.hiddenClass);
    this.buttonsContainerTarget.classList.remove(this.hiddenClass);
  }

  _handleSuccess() {
    this.messageTarget.classList.add(this.flashSuccessClass);
    this.successButtonTarget.classList.remove(this.hiddenClass);
    this.buttonsContainerTarget.classList.remove(this.hiddenClass);
    this.messageContentTarget.innerHTML += successNote;
  }
}

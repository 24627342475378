import { Controller } from 'stimulus';

const timeout = 800;

export default class extends Controller {
  static targets = [];

  static classes = [];

  static values = {};

  connect() {
    this.connected = true;
    this._requestAnimationFrame();
  }

  disconnect() {
    this.connected = false;
  }

  _onAnimationFrame(timestamp) {
    if (!this.started_at) this.started_at = timestamp;

    const elapsed = (timestamp - this.started_at) % (timeout * 4);

    this.element.innerText = '.'.repeat(elapsed / timeout);

    if (this.connected) this._requestAnimationFrame();
  }

  _requestAnimationFrame() {
    window.requestAnimationFrame(this._onAnimationFrame.bind(this));
  }
}

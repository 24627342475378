import { Controller } from 'stimulus';
import Expandable from '../utils/expandable';

export default class extends Controller {
  static classes = ['open'];

  static targets = ['expandable', 'button'];

  static values = {
    buttonAlternate: String,
  };

  initialize() {
    this.recalculateHeight = this.recalculateHeight.bind(this);
  }

  connect() {
    this.openClassesList = this.openClass.split(' ');
    this.expandable = new Expandable(this.expandableTarget);
    window.addEventListener('resize', this.recalculateHeight);
    window.addEventListener('orientationchange', this.recalculateHeight);
  }

  disconnect() {
    window.removeEventListener('resize', this.recalculateHeight);
    window.removeEventListener('orientationchange', this.recalculateHeight);
  }

  toggleSection(ev) {
    ev.preventDefault();

    if (this._isOpen()) {
      this._close();
    } else {
      this._open();
    }
  }

  recalculateHeight() {
    if (this._isOpen()) {
      this.expandable.expand();
    }
  }

  _open() {
    this.element.classList.add(...this.openClassesList);
    this.expandable.expand();

    if (this.hasButtonAlternateValue) {
      this._alterButtonText();
    }
  }

  _close() {
    this.element.classList.remove(...this.openClassesList);
    this.expandable.retract();

    if (this.hasButtonAlternateValue) {
      this._alterButtonText();
    }
  }

  _isOpen() {
    return this.openClassesList.every((cls) => this.element.classList.contains(cls));
  }

  _alterButtonText() {
    const value = this.buttonAlternateValue;
    this.buttonAlternateValue = this.buttonTarget.innerText;
    this.buttonTarget.innerText = value;
  }
}

/* global YT */

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    id: String,
  };

  static classes = ['hidden'];

  static targets = ['player'];

  connect() {
    this.playerReady = false;
    this.autoplay = false;
    if (YT.Player !== undefined) {
      this._onPlayerLoaded();
    } else {
      window.onYouTubeIframeAPIReady = this._onPlayerLoaded.bind(this);
    }
  }

  disconnect() {
    if (this.player) {
      this.player.destroy();
    }
  }

  play(ev) {
    ev.preventDefault();
    this.autoplay = true;
    this._showPlayer();

    if (this.playerReady) {
      this.player.playVideo();
    }
  }

  reset(ev) {
    ev.preventDefault();
    this._hidePlayer();
    this.player.stopVideo();
    this.autoplay = false;
  }

  _showPlayer() {
    this.playerTarget.classList.remove(this.hiddenClass);
    document.body.style.overflow = 'hidden';
  }

  _hidePlayer() {
    this.playerTarget.classList.add(this.hiddenClass);
    document.body.style.overflow = 'auto';
  }

  _containerSize() {
    return {
      width: 560,
      height: 315,
    };
  }

  _onPlayerLoaded() {
    const events = {
      onReady: this._onPlayerReady.bind(this),
    };
    this.player = new YT.Player('youtube-player', {
      ...this._containerSize(),
      playerVars: {
        enablejsapi: 1,
        origin: window.location.href,
      },
      videoId: this.idValue,
      events,
    });
  }

  _onPlayerReady() {
    this.playerReady = true;
    if (this.autoplay) {
      this.player.playVideo();
    }
  }
}

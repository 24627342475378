import React from 'react';
import { getMessages } from '../services/firebase';
import { fullDay } from '../utils/date_utils.js';

function useMessages (chatRoom) {
  const [messages, setMessages] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = getMessages(chatRoom.chat_room_id, msgs => {
      // sort the messages by date
      msgs.sort((a, b) => a.sent_at.toDate() - b.sent_at.toDate());

      // group messages by day
      const messagesByDay = new Map();
      msgs.forEach(message => {
        const day = fullDay(message.sent_at.toDate());
        const messagesForDay = messagesByDay.get(day) || [];
        messagesForDay.push(message);
        messagesByDay.set(day, messagesForDay);
      });

      const messagesArray = [];

      for (const [key, value] of messagesByDay) {
        messagesArray.push(key);
        messagesArray.push(...value);
      }

      setMessages(messagesArray);
      setIsLoading(false);
    });
    return unsubscribe;
  }, [chatRoom.chat_room_id]);

  const value = {
    isLoading,
    messages,
  };
  return value;
}

export { useMessages };

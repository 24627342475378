import { Controller } from 'stimulus';
import consumer from '../utils/actioncable-consumer';

const WS_CHANNEL_NAME = 'PaymentDetailsChannel';

export default class extends Controller {
  static targets = ['heading', 'message', 'messageHeading', 'messageContent', 'entry'];

  static classes = ['flashDefault', 'flashError', 'flashSuccess'];

  static values = { id: Number };

  connect() {
    if (this.idValue) {
      this._createSubscription(this.idValue);
    }
  }

  _createSubscription(paymentMethodId) {
    consumer.subscriptions.create(
      {
        channel: WS_CHANNEL_NAME,
        id: paymentMethodId,
      },
      {
        received: this._wsReceiver.bind(this),
      },
    );
  }

  _wsReceiver(data) {
    this.messageTarget.classList.remove(this.flashDefaultClass);
    if (data.type === 'success') {
      this.messageTarget.classList.add(this.flashSuccessClass);
      this._removeRow(this.idValue);
    } else {
      this.messageTarget.classList.add(this.flashErrorClass);
    }
    this.messageHeadingTarget.innerText = data.payload.heading;
    this.messageContentTarget.innerText = data.payload.message;
  }

  _removeRow(id) {
    const target = this.entryTargets.find((row) => parseInt(row.dataset.id, 10) === id);
    if (!target) return;

    target.parentNode.removeChild(target);
  }
}

/* global CollectJS */

import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [
    'error',
    'errorHeading',
    'errorMessage',
    'latitude',
    'longitude',
    'form',
    'token',
    'details',
    'ccnumber',
    'ccexp',
    'submitButton',
    'paymentMethod',
  ];

  static classes = ['errorHidden'];

  static values = {
    type: String,
  };

  geolocationTimeoutMs = 4000;

  currency = 'USD';

  connect() {
    this._skipProcessing = false;
    if (!this._isHypur()) {
      this._configNmi();
    }
  }

  // eslint-disable-next-line consistent-return
  onFormSubmit(ev) {
    if (this._skipProcessing) return;

    if (this._isHypur()) {
      this._hypurSubmit(ev);
    } else {
      this._nmiSubmit(ev);
    }
  }

  _hypurSubmit(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this._lockSubmit();

    this._getCoordinates()
      .then((pos) => {
        this._positionFound(pos);
        this.formTarget.submit();
      })
      .catch((error) => {
        this._requestError(error);
        this._unlockSubmit();
      });
  }

  _nmiSubmit(ev) {
    if (!this._shouldRequestPayment()) return;

    ev.preventDefault();
    ev.stopPropagation();
    CollectJS.startPaymentRequest();
  }

  _lockSubmit() {
    Rails.disableElement(this.submitButtonTarget);
  }

  _unlockSubmit() {
    Rails.enableElement(this.submitButtonTarget);
  }

  _getCoordinates() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, { timeout: this.geolocationTimeoutMs });
    });
  }

  _positionFound(position) {
    this.errorTarget.classList.add(this.errorHiddenClass);
    this.latitudeTarget.value = position.coords.latitude;
    this.longitudeTarget.value = position.coords.longitude;
    this._skipProcessing = true;
  }

  _requestError(error) {
    const heading = 'Location required';
    let message = '';
    switch (error.code) {
      case 1: // PERMISSION_DENIED
        message = 'You need to allow us to retrieve your location as this is required by the payment provider.';
        break;
      case 2: // POSITION_UNAVAILABLE
      case 3: // TIMEOUT
      default:
        // UNEXPECTED
        message =
          'We could not fetch your location due to some error. ' +
          'Please check you browser and operating system preferences to make sure location is allowed. ' +
          'We need to know your coordinates as this is required by the payment provider.';
        break;
    }
    this.errorHeadingTarget.innerText = heading;
    this.errorMessageTarget.innerText = message;
    this.errorTarget.classList.remove(this.errorHiddenClass);
  }

  _isHypur() {
    return this.typeValue === 'hypur';
  }

  _configNmi() {
    const config = {
      theme: 'bootstrap',
      primaryColor: '#d3ab3a',
      secondaryColor: '#d3d3d3',
      currency: this.currency,
      fields: {
        cvv: { display: 'required' },
      },
    };
    config.callback = this._nmiCallback.bind(this);
    config.validationCallback = this._unlockSubmit.bind(this);
    config.timeoutCallback = this._unlockSubmit.bind(this);
    CollectJS.configure(config);
  }

  _nmiCallback(response) {
    this._lockSubmit();
    this._skipProcessing = true;
    this.tokenTarget.value = response.token;
    this.detailsTarget.value = JSON.stringify(response.card);
    this.formTarget.submit();
  }

  _shouldRequestPayment() {
    if (!this.paymentMethodTarget.selectedIndex) {
      return true;
    }

    return !this.paymentMethodTarget.options[this.paymentMethodTarget.selectedIndex].value;
  }
}

import { Link, useParams } from "react-router-dom";
import { MessageInput } from "./../MessageInput";
import { MessageList } from "./../MessageList";
import "./styles.scss";

function ChatRoom() {
  const params = useParams();
  return (
    
    <>
      <h2>Messages</h2>
      <div>
        <Link to={`/${params.userId}`}>⬅️ Back to all rooms</Link>
      </div>
      <div className="messages-container">
        <MessageList roomId={params.id} userId={params.userId} />
        <MessageInput roomId={params.id} userId={params.userId} />
      </div>
    </>
    
  );
}


export { ChatRoom };

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.audienceDropdown();
    this.primaryContentDropdown();
    this.secondaryContentDropdown();
  }

  audienceDropdown() {
    $('#community_audience').multiselect({
      nonSelectedText: 'Select audience',
      maxHeight: 150,
      includeSelectAllOption: true,
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '100%',
      numberDisplayed: 2
    });
  }

  primaryContentDropdown() {
    $('#primary_creator_type').multiselect({
      nonSelectedText: 'Select Your Primary Content',
      maxHeight: 150,
      includeSelectAllOption: true,
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '100%',
      numberDisplayed: 2
    });
  }

  secondaryContentDropdown() {
    $('#secondary_creator_type').multiselect({
      nonSelectedText: 'Select Your Secondary Content',
      maxHeight: 150,
      includeSelectAllOption: true,
      enableFiltering: true,
      enableCaseInsensitiveFiltering: true,
      buttonWidth: '100%',
      numberDisplayed: 2
    });
  }
}
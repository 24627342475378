export default class {
  constructor(element) {
    this.element = element;
  }

  expand() {
    this._setMaxHeight();
  }

  retract() {
    this.element.style.maxHeight = 0;
  }

  _setMaxHeight() {
    this.element.style.maxHeight = `${this.element.scrollHeight}px`;
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.counrtyOptions();
    this.stateOptions();
    this.creatorOptions();
    this.followersOptions();
    this.communityOptions();
  }

  counrtyOptions() {
    $('#country').multiselect({
      nonSelectedText: 'Select Country',
      enableFiltering: true,
      includeSelectAllOption: true,
      enableCaseInsensitiveFiltering: true
  });
  }

  stateOptions() {
    $("#state").multiselect({
      nonSelectedText: "Select State",
      enableFiltering: true,
      includeSelectAllOption: true,
      enableCaseInsensitiveFiltering: true
    });
  }
  
  creatorOptions() {
    $("#creator_type").multiselect(
      {
          nonSelectedText: "Select Creator Type",
          enableFiltering: true,
          includeSelectAllOption: true,
          enableCaseInsensitiveFiltering: true
      }
    );
  }

  followersOptions() {
    $("#followers_range").multiselect({
      nonSelectedText: "Select Followers Range",
      enableFiltering: true,
      includeSelectAllOption: true,
      enableCaseInsensitiveFiltering: true
  });
  }

  communityOptions() {
    $("#community_audience").multiselect({
      nonSelectedText: "Select Community Audience",
      enableFiltering: true,
      includeSelectAllOption: true,
      enableCaseInsensitiveFiltering: true
  })
  }
}
import { Controller } from 'stimulus';

const LEAVING_PAGE_MESSAGE = 'Are you sure you want to leave this page? All unsaved data will be lost!';

export default class extends Controller {
  static targets = ['input'];

  static classes = [];

  static values = {};

  connect() {
    this.initialInputValues = {};
    this.inputTargets.forEach((input) => {
      this.initialInputValues[input.name] = input.value;
    });
    this.isSubmit = false;
  }

  formSubmit() {
    this.isSubmit = true;
  }

  leavingPage(ev) {
    if (!this.isSubmit && this._inputsChanged()) {
      if (ev.type === 'beforeunload') {
        // eslint-disable-next-line no-param-reassign
        ev.returnValue = LEAVING_PAGE_MESSAGE;
        return ev.returnValue;
      }
      if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
        ev.preventDefault();
        return false;
      }
    }
    this.isSubmit = false;
    return true;
  }

  _inputsChanged() {
    return !this.inputTargets.every((input) => this.initialInputValues[input.name] === input.value);
  }
}

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useChatRooms } from '../../hooks/useChatRooms';
import { ChatRoomDetailProvider } from './../../context/chatRoomDetailsContext';
import { ChatRoomListingView } from './../ChatRoomListing';
import { ChatRoomDetailView } from './../ChatRoomDetail';
import { ChatRoomDetailContext } from '../../context/chatRoomDetailsContext';
import {
  MainContainer,
  Sidebar,
  ConversationList,
  ConversationHeader,
  Conversation,
  Avatar,
} from '@chatscope/chat-ui-kit-react';
import './styles.scss';

function Landing ({ userId }) {
  const containerRef = React.useRef(null);
  const { isLoading, chatRooms } = useChatRooms(userId);

  React.useLayoutEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sidebarVisible, setSidebarVisible] = useState(isMobile ? true : false);
  const [sidebarStyle, setSidebarStyle] = useState(isMobile ? {
    display: "flex",
    flexBasis: "auto",
    width: "100%",
    maxWidth: "100%"
  } : {});
  const [chatContainerStyle, setChatContainerStyle] = useState({});
  const [conversationContentStyle, setConversationContentStyle] = useState({});
  const [conversationAvatarStyle, setConversationAvatarStyle] = useState({});

  const handleBackClick = () => setSidebarVisible(!sidebarVisible);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
        setSidebarVisible(false)
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleConversationClick = useCallback(() => {
    if (sidebarVisible) {
      setSidebarVisible(false);
    }
  }, [sidebarVisible, setSidebarVisible]);
  
  useEffect(() => {
    if (sidebarVisible) {
      setSidebarStyle({
        display: "flex",
        flexBasis: "auto",
        width: "100%",
        maxWidth: "100%"
      });
      setConversationContentStyle({
        display: "flex"
      });
      setConversationAvatarStyle({
        marginRight: "1em"
      });
      setChatContainerStyle({
        display: "none"
      });
    } else {
      setSidebarStyle({});
      setConversationContentStyle({});
      setConversationAvatarStyle({});
      setChatContainerStyle({});
    }
  }, [sidebarVisible, setSidebarVisible, setConversationContentStyle, setConversationAvatarStyle, setSidebarStyle, setChatContainerStyle]);

  return (
    <div
      style={{
        height: '600px',
        position: 'relative',
      }}
      ref={containerRef}
    >
      <ChatRoomDetailProvider>
        <MainContainer responsive>
          <Sidebar
            position='left'
            scrollable={false}
            style={sidebarStyle}
          >
            <ConversationHeader>
              <ConversationHeader.Content userName='Messages' />
            </ConversationHeader>
            {isLoading ? (
              <ConversationList loading={true} />
            ) : (
              <ChatRoomListingView
                items={chatRooms}
                handleConversationClick={handleConversationClick}
                conversationContentStyle={conversationContentStyle}
              />
            )}
          </Sidebar>
          <ChatRoomDetailView chatContainerStyle={chatContainerStyle} handleBackClick={handleBackClick} />
        </MainContainer>
      </ChatRoomDetailProvider>
    </div>
  );
}

// function SideBar (sidebarStyle, userId, handleConversationClick, conversationContentStyle) {
//   const { isLoading, chatRooms } = useChatRooms(userId);

//   return (
//     <Sidebar
//       position='left'
//       scrollable={false}
//       style={{
//         display: 'flex',
//         flexBasis: 'auto',
//         width: '100%',
//         maxWidth: '100%',
//       }}
//     >
//       <ConversationHeader>
//         <ConversationHeader.Content userName='Messages' />
//       </ConversationHeader>
//       {isLoading ? (
//         <ConversationList loading={true} />
//       ) : (
//         <ChatRoomListingView
//           items={chatRooms}
//           handleConversationClick={handleConversationClick}
//           conversationContentStyle={conversationContentStyle}
//         />
//       )}
//     </Sidebar>
//   );
// }

export { Landing };

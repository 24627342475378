import React, { useContext } from 'react';
import { ConversationList, Conversation, Avatar, MessageList } from '@chatscope/chat-ui-kit-react';
import { ChatRoomDetailContext } from '../../context/chatRoomDetailsContext';
import { dateDDMMMYY } from '../../utils/date_utils.js';
import { markConversationAsread } from '../../services/firebase';
import './style.scss';

const ChatRoomListingView = ({ items, handleConversationClick, conversationContentStyle }) => {
  const { updateSelectedChatRoom } = useContext(ChatRoomDetailContext);
  const { selectedChatRoom } = useContext(ChatRoomDetailContext);

  const updateSelectedConversation = async (chatRoom) => {
    const unread = chatRoom.unread_count[chatRoom.current_user.user_id] > 0;
    if (unread) {
      await markConversationAsread(chatRoom.current_user.user_id, chatRoom.chat_room_id);
    }
  };

  if (items.length === 0) {
    return (
      <MessageList.Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          textAlign: 'center',
          fontSize: '1.2em',
        }}
      >
        No Messages
      </MessageList.Content>
    );
  }

  function getPlainTextFromHtml(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  return (
    <ConversationList>
      {items.map((item) => (
        <Conversation
          key={item.chat_room_id}
          onClick={() => {
            handleConversationClick();
            updateSelectedChatRoom(item);
            updateSelectedConversation(item);
          }}
          lastActivityTime={
            <span
              style={{
                width: '65px',
              }}
            >
              {dateDDMMMYY(item.last_message_sent_at)}
            </span>
          }
          unreadDot={item.unread_count[item.current_user.user_id] > 0}
          active={item.chat_room_id == (selectedChatRoom && selectedChatRoom.chat_room_id)}
        >
          <Conversation.Content
            name={item.opp_user.display_name}
            lastSenderName={item.last_msg_user.display_name}
            info={getPlainTextFromHtml(item.last_message_body)}
            style={conversationContentStyle}
          />
        </Conversation>
      ))}
    </ConversationList>
  );
};

export { ChatRoomListingView };

import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  where,
  getDocs,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from './firebaseConfig';
import { getFirebaseToken } from './firebaseMessage';

async function loginWithGoogle () {
  try {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    const { user } = await signInWithPopup(auth, provider);

    return { uid: user.uid, displayName: user.displayName };
  } catch (error) {
    if (error.code !== 'auth/cancelled-popup-request') {
      console.error(error);
    }
    return null;
  }
}

async function sendMessage (roomId, userId, text) {
  try {
    await addDoc(collection(db, 'messages'), {
      chat_room_id: roomId,
      message_body: text.trim(),
      sent_by: userId,
      sent_at: serverTimestamp(),
    });
  } catch (error) {
    console.error(error);
  }
}

function getMessages (roomId, callback) {
  return onSnapshot(
    query(collection(db, 'messages'), where('chat_room_id', '==', roomId), orderBy('sent_at', 'asc')),
    querySnapshot => {
      const messages = querySnapshot.docs.map(x => ({
        id: x.id,
        ...x.data(),
      }));

      callback(messages);
    },
  );
}

function getChatRooms (userId, callback) {
  return onSnapshot(
    query(
      collection(db, 'chat-rooms'),
      where('participants', 'array-contains', userId),
      orderBy('last_message_sent_at', 'desc'),
    ),
    querySnapshot => {
      const chatRooms = querySnapshot.docs.map(x => ({
        id: x.id,
        ...x.data(),
      }));

      callback(chatRooms);
    },
  );
}

async function getUserById (userId) {
  const q = query(collection(db, 'users'), where('rails_id', '==', userId));

  const querySnapshot = await getDocs(q);
  return new Promise((resolve, reject) => {
    var userName;
    querySnapshot.forEach(doc => {
      userName = doc.data().display_name;
    });
    resolve(userName);
  });
}

async function markConversationAsread (userId, chatroomId) {
  try {
    const chatRoomRef = doc(db, 'chat-rooms', chatroomId);
    try {
      await updateDoc(chatRoomRef, {
        [`unread_count.${userId}`]: 0,
      });

      return;
    } catch (error) {
      console.error(error);
    }
  } catch (error) {
    console.error(error);
  }
}

async function addTokenToUser (userId, token) {
  try {
    const q = query(collection(db, 'users'), where('rails_id', '==', userId));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const userRef = querySnapshot.docs[0];
      const userRecord = querySnapshot.docs[0].data();
      const tokens = userRecord.tokens || [];
      if (tokens.includes(token)) {
        return;
      } else {
        const userUpdatedRef = doc(db, 'users', userRef.id);
        tokens.push(token);
        await updateDoc(userUpdatedRef, { tokens: Array.from(new Set(tokens)) });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

async function removeFirebaseToken (userId) {
  const token = getFirebaseToken();

  try {
    const q = query(collection(db, 'users'), where('rails_id', '==', userId));

    const querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      const userRef = querySnapshot.docs[0];
      const userRecord = querySnapshot.docs[0].data();
      const tokens = userRecord.tokens || [];
      if (tokens.includes(token)) {
        const userUpdatedRef = doc(db, 'users', userRef.id);
        const newTokens = tokens.filter((element) => element !== token);
        await updateDoc(userUpdatedRef, { tokens: Array.from(new Set(newTokens)) });
      } else {
        return;
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export {
  loginWithGoogle,
  sendMessage,
  getMessages,
  getChatRooms,
  getUserById,
  markConversationAsread,
  addTokenToUser,
  removeFirebaseToken,
};

import { async } from '@firebase/util';
import React from 'react';
import { getChatRooms, getUserById } from '../services/firebase';

function useChatRooms(userId) {
  const [chatRooms, setChatRooms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribe = getChatRooms(userId, async (chatRooms) => {
      const updatedChatRooms = await Promise.all(
        chatRooms.map(async (element) => {
          const { participants } = element;
          const participantsData = [];
          await Promise.all(
            participants.map(async (element) => {
              const userName = await getUserById(element);
              participantsData.push({
                user_id: element,
                display_name: userName,
              });
            }),
          );
          const oppUser = participantsData.filter((value) => value.user_id != userId)[0];
          element.opp_user = oppUser;
          const currentUser = participantsData.filter((value) => value.user_id == userId)[0];
          element.current_user = currentUser;
          const lastMsgUserId = element.last_message_by;
          element.last_msg_user =
            oppUser.user_id == lastMsgUserId
              ? oppUser
              : {
                  user_id: lastMsgUserId,
                  display_name: lastMsgUserId ? 'You' : null,
                };
          return element;
        }),
      );
      setIsLoading(false);
      setChatRooms(updatedChatRooms);
    });

    return unsubscribe;
  }, [userId]);

  const value = {
    isLoading,
    chatRooms,
  };
  return value;
}

export { useChatRooms };

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['control', 'paymentTypeSelect'];

  static classes = ['hidden'];

  static values = {};

  connect() {
    this.changePaymentType();
  }

  changePaymentType() {
    const type = this._selectedPaymentType();

    this._showPaymentTypeControls(type);
  }

  _selectedPaymentType() {
    if (!this.paymentTypeSelectTarget.selectedIndex) {
      return null;
    }

    return this.paymentTypeSelectTarget.options[this.paymentTypeSelectTarget.selectedIndex].value;
  }

  _showPaymentTypeControls(type) {
    this.controlTargets.forEach((target) => this._toggleControl(target, type));
  }

  _toggleControl(control, paymentType) {
    if (control.dataset.controlType === paymentType) {
      control.classList.remove(this.hiddenClass);
    } else {
      control.classList.add(this.hiddenClass);
    }
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['paymentMethod', 'newMethodControl', 'existingMethodControl'];

  static classes = ['hidden'];

  static values = {};

  connect() {
    this.paymentMethodSelected();
  }

  paymentMethodSelected() {
    if (this._selectedPaymentMethod()) {
      this._showExistingMethodControls();
    } else {
      this._showNewMethodControls();
    }
  }

  _selectedPaymentMethod() {
    if (!this.paymentMethodTarget.selectedIndex) {
      return null;
    }

    return this.paymentMethodTarget.options[this.paymentMethodTarget.selectedIndex].value;
  }

  _showNewMethodControls() {
    this.newMethodControlTargets.forEach((target) => target.classList.remove(this.hiddenClass));
    this.existingMethodControlTargets.forEach((target) => target.classList.add(this.hiddenClass));
  }

  _showExistingMethodControls() {
    this.newMethodControlTargets.forEach((target) => target.classList.add(this.hiddenClass));
    this.existingMethodControlTargets.forEach((target) => target.classList.remove(this.hiddenClass));
  }
}
